/* eslint-disable no-console */
import { compose } from '@daangn/car-utils/misc';
import { Activity } from '@stackflow/core';

import { IS_PROD } from '@/constants/config';
import { appsflyerTracker } from '@/sdk/tracker/AppsflyerTracker';
import { brazeTracker } from '@/sdk/tracker/BrazeTracker';
import { ExperimentGroupMap } from '@/store/experiment';
import { copyToClipboard } from '@/utils/clipboard';
import { convertToCamelCase, filterNil } from '@/utils/misc';
import { getDefaultEventParams } from '@/utils/track';

import { amplitudeTracker } from './AmplitudeTracker';
import { CustomEvent, customEventDescriptions } from './CustomEvent';
import { firebaseTracker } from './FirebaseTracker';
import { ga4Tracker } from './GA4Tracker';
import { mixpanelTracker } from './MixpanelTracker';
import { PvEvent, pvEventDescriptions } from './PvEvent';
import { UserIdentity } from './TrackerImpl';

const trackers = [
  ga4Tracker,
  firebaseTracker,
  amplitudeTracker,
  mixpanelTracker,
  appsflyerTracker,
  brazeTracker,
];

type ActivitySet = {
  currentActivity?: Activity;
  experimentGroupMap?: Partial<ExperimentGroupMap>;
  prevActivity?: Activity;
};

const unifyEventParams = compose(filterNil, convertToCamelCase);

let eventSummaries: [string, number][] = [];

const logEvent = (name: string, params?: Record<string, any>) => {
  type CustomEventName = CustomEvent['name'];
  type PvEventName = PvEvent['name'];
  if (!IS_PROD) {
    const eventSummary = `${name}\n${
      pvEventDescriptions[name as PvEventName] ?? customEventDescriptions[name as CustomEventName]
    }\n${Object.entries(params ?? {})
      .map(([key, value]) => `\t${key}: ${JSON.stringify(value, undefined, 2)}`)
      .join('\n')}`;

    eventSummaries.push([eventSummary, Date.now()]);
    const tag = name.includes('impression')
      ? 'impression'
      : name.includes('enter')
        ? 'enter'
        : name.includes('pageview')
          ? 'pv'
          : 'click';
    console.groupCollapsed(`Event Tracked: [${tag}] ${name}`);
    console.log(JSON.stringify(params, undefined, 2));
    console.groupEnd();
  }
};

export const flushEventSummaries = (start: number, end: number) => {
  copyToClipboard(
    eventSummaries
      .filter(([, timestamp]) => timestamp > start && timestamp < end)
      .map(([text]) => text)
      .join('\n\n')
  );
  eventSummaries = [];
};

class CarTracker {
  init(info: UserIdentity) {
    trackers.forEach((item) => {
      try {
        item.init(info);
      } catch (e) {
        console.warn(e);
      }
    });
  }

  async trackEvent<TName extends CustomEvent['name']>(
    name: TName,
    params: Extract<CustomEvent, { name: TName }>['params'],
    options?: ActivitySet
  ) {
    const { currentActivity, prevActivity, experimentGroupMap } = options ?? {};
    const eventName = name;
    const eventParams = await unifyEventParams({
      ...getDefaultEventParams(currentActivity, prevActivity, experimentGroupMap),
      ...(params ?? {}),
    });
    logEvent(eventName, eventParams);
    trackers.forEach((item) => {
      try {
        item.trackEvent(eventName, eventParams);
      } catch (e) {
        console.warn(e);
      }
    });
  }

  async trackPageView<TName extends PvEvent['name']>(
    name: TName,
    params: Extract<PvEvent, { name: TName }>['params'],
    options: ActivitySet
  ) {
    const { currentActivity, prevActivity, experimentGroupMap } = options ?? {};
    if (!currentActivity) {
      throw Error('activity is not defined');
    }
    const defaultEventParams = getDefaultEventParams(
      currentActivity,
      prevActivity,
      experimentGroupMap
    );
    const eventParams = await unifyEventParams({
      ...defaultEventParams,
      ...params,
    });
    logEvent(name, eventParams);
    trackers.forEach((item) => {
      try {
        item.trackPageView(name, eventParams);
      } catch (e) {
        console.warn(e);
      }
    });
  }
}

export const carTracker = new CarTracker();
