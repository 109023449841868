import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import fetchGraphQL from './fetchGraphQL';
import getDataID from './getDataID';

const environment = new Environment({
  network: Network.create(fetchGraphQL),
  store: new Store(new RecordSource()),
  getDataID,
});

export default environment;
