/**
 * @generated SignedSource<<586f3d3fe6bd493b28c11dbd63966f4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CarType = "BIG" | "BUSINESS" | "ETC" | "MIDDLE" | "SMALL" | "SUV";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
export type Gear = "AUTO" | "CVT" | "MANUAL" | "SEMI_AUTO" | "UNKNOWN";
export type Manufacturer = "ACURA" | "ASTON_MARTIN" | "AUDI" | "BAIC" | "BENTLEY" | "BENZ" | "BMW" | "CADILLAC" | "CAMMSYS" | "CHEVROLET" | "CHEVROLET_GM_DAEWOO" | "CHRYSLER" | "CITROEN" | "CYAN" | "DAECHANG_MOTORS" | "DAEWOO_BUS" | "DFSK" | "DODGE" | "DS" | "ETC" | "FERRARI" | "FIAT" | "FORD" | "FOTON" | "FOX" | "GENESIS" | "GMC" | "HONDA" | "HUMMER" | "HYUNDAI" | "INFINITI" | "ISUZU" | "JAGUAR" | "JEEP" | "KIA" | "LAMBORGHINI" | "LAND_ROVER" | "LEXUS" | "LINCOLN" | "LOTUS" | "MAIV" | "MASERATI" | "MAYBACH" | "MAZDA" | "MC_LAREN" | "MINI" | "MITSUBISHI" | "NISSAN" | "OULLIM" | "PEUGEOT" | "PORSCHE" | "POWER_PLAZA" | "RENAULT_SAMSUNG" | "ROLLS" | "SAAB" | "SMART" | "SSANGYOUNG" | "SUBARU" | "SUZUKI" | "TESLA" | "TOYOTA" | "VOLKSWAGEN" | "VOLVO" | "ZHIDOU";
export type DraftArticleInput = {
  carName?: string | null | undefined;
  carNo?: string | null | undefined;
  carRegistrationMonth?: number | null | undefined;
  carRegistrationYear?: number | null | undefined;
  carType?: CarType | null | undefined;
  content?: string | null | undefined;
  driveDistance?: number | null | undefined;
  fuelType?: FuelType | null | undefined;
  gear?: Gear | null | undefined;
  madeMonth?: number | null | undefined;
  madeYear?: number | null | undefined;
  manufacturer?: Manufacturer | null | undefined;
  manufacturerEtc?: string | null | undefined;
  modelYear?: number | null | undefined;
  ownerName?: string | null | undefined;
  price?: number | null | undefined;
};
export type article_DraftArticleMutation$variables = {
  input: DraftArticleInput;
};
export type article_DraftArticleMutation$data = {
  readonly draftArticle: boolean;
};
export type article_DraftArticleMutation = {
  response: article_DraftArticleMutation$data;
  variables: article_DraftArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "draftArticle",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "article_DraftArticleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "article_DraftArticleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "78f395ad7825bfe6ff49c0805950ccd4",
    "id": null,
    "metadata": {},
    "name": "article_DraftArticleMutation",
    "operationKind": "mutation",
    "text": "mutation article_DraftArticleMutation(\n  $input: DraftArticleInput!\n) {\n  draftArticle(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "be847c82405bcbf195533a24a7215589";

export default node;
